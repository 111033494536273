<script>
import model from "@/mixins/model";

export default {
  name: "TaskAppointmentsForm",

  mixins: [model],
};
</script>

<template lang="pug">
.modal__row
  fieldset.modal__field
    label.label * Tipo:
    el-radio(v-model="model.category" label="doctor" checked) Médico
    el-radio(v-model="model.category" label="study") Estudio
</template>
