<script>
import model from "@/mixins/model";

export default {
  name: "TaskExercisesForm",

  mixins: [model],
};
</script>

<template lang="pug">
.modal__block
  .modal__section
    .modal__sign.sign
      .sign__icon
        micon(name="assignment_turned_in")
      h3.sign__title Ejecución
    article.modal__fields
      .modal__row
        fieldset.detail__field
          label.label * Repeticiones:
          ValidationProvider(name="Repeticiones", rules="required|min_value:0", v-slot="{ errors }")
            el-input(
              type="number"
              step="any"
              min="0"
              v-model="model.repetitions"
              :class="{'has-error': errors[0]}"
            )
            span.has-error {{ errors[0] }}

        fieldset.detail__field
          label.label * Peso (KG):
          ValidationProvider(name="Peso", rules="required|min_value:0", v-slot="{ errors }")
            el-input(
              type="number"
              step="any"
              min="0"
              v-model="model.weight"
              :class="{'has-error': errors[0]}"
            )
            span.has-error {{ errors[0] }}

        fieldset.detail__field
          label.label * Series:
          ValidationProvider(name="Series", rules="required|min_value:0", v-slot="{ errors }")
            el-input(
              type="number"
              step="any"
              min="0"
              v-model="model.series"
              :class="{'has-error': errors[0]}"
            )
            span.has-error {{ errors[0] }}

      .modal__row
        fieldset.detail__field
          label.label * Duración (Min):
          ValidationProvider(name="Duración", rules="required", v-slot="{ errors }")
            el-input(
              v-model="model.duration"
            )
            span.has-error {{ errors[0] }}

        fieldset.detail__field
          label.label * Distancia (Mts):
          ValidationProvider(name="Distancia", rules="required|min_value:0", v-slot="{ errors }")
            el-input(
              type="number"
              step="any"
              min="0"
              v-model="model.distance"
              :class="{'has-error': errors[0]}"
            )
            span.has-error {{ errors[0] }}
</template>
