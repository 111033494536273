<script>
import model from "@/mixins/model";
import FileUpload from "@/components/ui/FileUpload";

export default {
  name: "TaskMedia",

  components: {
    FileUpload,
  },

  mixins: [model],

  props: {
    uploadMediaEndpoint: {
      type: String,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    onMediaError: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    isMediaTextEnabled() {
      return this.type === "recommendations";
    },
  },

  data() {
    return {
      hasFiles: false,
    };
  },

  watch: {
    "model.media.type": function () {
      if (
        this.model.media.type === "image" ||
        this.model.media.type === "audio"
      ) {
        this.model.media.content = [];
      }
    },
  },

  methods: {
    resetMediaContent() {
      if (
        this.model.media.type === "image" ||
        this.model.media.type === "audio"
      ) {
        this.model.media.content = [];
      } else {
        this.model.media.content = [{ value: "" }];
      }
    },

    getFiles() {
      return this.model.media.content.slice();
    },

    getDropdownOptions() {
      return {
        method: "put",
        paramName: "avatar",
        maxFiles: 1,
      };
    },

    onFilesAdded() {
      this.hasFiles = true;
    },

    onFileRemoved() {
      this.hasFiles = false;
      const isImage = this.model.media.type === "image";
      const isAudio = this.model.media.type === "audio";
      if (isImage || isAudio) {
        this.resetMediaContent();
      }
    },

    uploadFiles() {
      if (this.$refs.fileUpload && this.hasFiles) {
        this.$refs.fileUpload.startUpload();
      } else {
        this.closeModal();
      }
    },
  },
};
</script>

<template lang="pug">
  .modal__block
    .modal__section
      .modal__sign.sign
        .sign__icon
          micon(name="image")
        h3.sign__title Media
      article.modal__fields
        .modal__row
          fieldset.modal__field
            label.label Tipo de Archivo:
            el-select(v-model="model.media.type" v-on:change="resetMediaContent()")
              el-option(label="Imagen" value="image")
              el-option(label="Audio" value="audio")
              el-option(label="Video" value="video")
              el-option(v-if="isMediaTextEnabled" label="Texto" value="text")

        .modal__row(v-if="model.media.type === 'image' || model.media.type === 'audio'")
          fieldset.modal__field
            label.label(v-if="model.media.type === 'image'") Imagen:
            label.label(v-else) Audio:
            file-upload(
              ref="fileUpload",
              :type="model.media.type"
              :url="uploadMediaEndpoint",
              :files="getFiles()",
              :dropzone-options="getDropdownOptions()"
              @file-added="onFilesAdded",
              @files-removed="onFileRemoved"
              @fileupload-success="closeModal"
              @fileupload-error="onMediaError"
            )

        .modal__row(v-if="model.media.type === 'video'")
          fieldset.modal__field
            label.label Link del Video:
            ValidationProvider(name="Link", rules="required|url", v-slot="{ errors }")
              el-input(v-model="model.media.content[0].value", :class="{'has-error': errors[0]}")
              span.has-error {{ errors[0] }}

        .modal__row(v-if="model.media.type === 'text'")
          fieldset.modal__field
            label.label * Texto:
            ValidationProvider(name="Texto", rules="required", v-slot="{ errors }")
              el-input(
                type="textarea"
                v-model="model.media.content[0].value"
                rows="10"
                maxlength="250"
                :class="{'has-error': errors[0]}"
              )
              span.has-error {{ errors[0] }}
      article.modal__fields
</template>
