import { render, staticRenderFns } from "./TaskMedia.vue?vue&type=template&id=0978f5c5&lang=pug&"
import script from "./TaskMedia.vue?vue&type=script&lang=js&"
export * from "./TaskMedia.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports