<script>
import model from "@/mixins/model";

export default {
  name: "TaskMedicationsForm",

  mixins: [model],
};
</script>

<template lang="pug">
.modal__row
  fieldset.modal__field
    label.label Cantidad:
    ValidationProvider(name="cantidad", rules="required", v-slot="{ errors }")
      el-input(type="number" v-model="model.quantity")
      span.has-error {{ errors[0] }}
</template>
