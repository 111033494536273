<script>
//- TODO: make it work image upload

import model from "@/mixins/model";

export default {
  name: "TaskCheckinsForm",

  mixins: [model],

  methods: {
    addQuestion() {
      this.model.answerValues.push({
        question: "",
        answers: [
          {
            measurement: "green",
            type: "text",
            content: "",
          },

          {
            measurement: "red",
            type: "text",
            content: "",
          },
        ],
      });
    },

    removeQuestion(index) {
      this.model.answerValues.splice(index, 1);
    },

    removeAnswer(answerValue, index) {
      if (answerValue.answers.length <= 2) {
        return;
      }
      answerValue.answers.splice(index, 1);
    },

    addAnswer(answerValue) {
      answerValue.answers.push({
        measurement: "green",
        type: "text",
        content: "",
      });
    },
  },
};
</script>

<template lang="pug">
.modal__block
  .modal__section
    .modal__sign.sign
      .sign__icon
        micon(name="insert_chart_outlined")
      h3.sign__title Nivel Alerta
    article.modal__fields
      span(v-for='(answerValue, index) in model.answerValues')
        .modal__row
          fieldset.modal__field(v-if="model.answerValues.length > 1")
            h2 Pregunta {{ index +1 }}
          fieldset.modal__field.close
            el-button(v-if="model.answerValues.length > 1" type="danger" icon="el-icon-delete" @click="removeQuestion(index)")

        .modal__row
          fieldset.modal__field
            label.label * Pregunta:
            ValidationProvider(name="Pregunta", rules="required", v-slot="{ errors }")
              el-input(v-model="answerValue.question")
              span.has-error {{ errors[0] }}


        .modal__row.checkins__answers__title
          fieldset.modal__field
            label.label
              b Respuestas

        .checkins__answers(v-for="(answer, index) in answerValue.answers")
          .modal__row
            fieldset.modal__field
              label.label * Respuesta {{ index + 1 }}
          .modal__row
            fieldset.modal__field
              label.label Tipo de Alerta:
              el-select(v-model="answer.measurement" default-first-option)
                el-option(label="Verde" value="green")
                el-option(label="Amarillo" value="yellow")
                el-option(label="Rojo" value="red")

            fieldset.modal__field
              label.label Formato de Respuesta:
              el-select(v-model="answer.type" default-first-option)
                el-option(label="Texto" value="text")
                //- el-option(label="Imagen" value="image")

          .modal__row
            fieldset.modal__field
              label.label(v-if="answer.type === 'text'") * Texto {{ index + 1 }}:
              ValidationProvider(:name="`Texto ${index +1}`", rules="required", v-slot="{ errors }")
                el-input(type="textarea" v-model="answer.content")
                span.has-error {{ errors[0] }}

              //- label.label(v-if="answer.type === 'image'") Imagen:
                file-upload(
                  ref="fileUpload",
                  type="image",
                  :files="[]", 
                  :url="uploadMediaEndpoint",
                  method="put",
                  param-name="avatar",
                  @fileupload-success="uploadFilesSuccess",
                  @files-added="filesAdded"
                )

              small(v-show="answerValue.answers.length > 2")
                a(@click="removeAnswer(answerValue, index)") &times; Eliminar Respuesta {{ index + 1 }}

        .modal__row.checkins__answers__add
          fieldset.modal__field
            a(@click="addAnswer(answerValue)") + Agregar Respuesta
          fieldset.modal__field

      el-button.button(@click="addQuestion") + Agregar Pregunta
</template>

<style lang="scss">
.close {
  flex: 1;
  align-items: end;
}

.checkins__answers__title {
  margin-top: 20px;
}

.checkins__answers {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid $light-gray;
}

.checkins__answers__add {
  margin-bottom: 20px;
}
</style>
