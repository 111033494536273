<script>
import model from "@/mixins/model";

export default {
  name: "TaskNotification",

  mixins: [model],

  props: {
    showPeriodization: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sectionIconOn() {
      const notificationIconOn =
        (this.showPeriodization && this.model.periodization.shouldNotify) ||
        this.model.shouldAlert;
      return notificationIconOn;
    },
  },
};
</script>

<template lang="pug">
.modal__block
  .modal__section
    .modal__sign.sign
      .sign__icon
        micon(name="notifications_active" v-show="sectionIconOn")
        micon(name="notifications_off" v-show="!sectionIconOn")
      h3.sign__title Notificaciones
    article.modal__fields
      .row(v-if="showPeriodization")
        fieldset
          el-checkbox(v-model="model.shouldNotify")
            b Activar Notificationes Push
      .row
        fieldset
          el-checkbox(v-model="model.shouldAlert")
            b Mostrar alertas en Dashboard
</template>
