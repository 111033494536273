import padRight from "pad-right";
import padLeft from "pad-left";

const dateHelpers = {};

dateHelpers.localTimeToUTC = function (localTime) {
  const [hours, minutes] = localTime.split(":");
  const date = new Date();

  date.setHours(hours);
  date.setMinutes(minutes);

  const utc = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  const utcHours = padLeft(utc.getHours().toString(), 2, 0);
  const utcMinutes = padRight(utc.getMinutes().toString(), 2, 0);
  return `${utcHours}:${utcMinutes}`;
};

// quick work around to fix demo
const hour24Map = {
  13: 1,
  14: 2,
  15: 3,
  16: 4,
  17: 5,
  18: 6,
  19: 7,
  20: 8,
  21: 9,
  22: 10,
  23: 11,
  24: 0,
};

dateHelpers.UTCTimeToLocal = function (UTCTime) {
  const [hours, minutes] = UTCTime.split(":");
  const isPM = hours > "11";
  const date = new Date(
    `1/01/2019 ${hour24Map[hours] || hours}:${minutes}:00 ${
      isPM ? "PM" : "AM"
    } UTC`
  );
  const localHour = padLeft(date.getHours().toString(), 2, 0);
  const localMinute = padRight(date.getMinutes().toString(), 2, 0);
  return `${localHour}:${localMinute}`;
};

export default dateHelpers;
