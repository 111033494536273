<script>
import model from "@/mixins/model";

const weekdayOptions = [
  { name: "Todos", value: 7 },
  { name: "Domingo", value: 0 },
  { name: "Lunes", value: 1 },
  { name: "Martes", value: 2 },
  { name: "Miércoles", value: 3 },
  { name: "Jueves", value: 4 },
  { name: "Viernes", value: 5 },
  { name: "Sábado", value: 6 },
];

// ["00:00", "00:30", "01:00", "01:30", ... "23:30"]
const timeOptions = [];
const hours = [...Array(24).keys()].map((i) => (i < 10 ? `0${i}` : `${i}`));
const minutes = ["00", "30"];

hours.forEach((hour) => {
  minutes.forEach((minute) => {
    timeOptions.push(`${hour}:${minute}`);
  });
});

// [1, 2, 3, ... 365]
const dayOptions = [...Array(365).keys()].map((i) => i + 1);

const defaultFromDay = 1;
const defaultToDay = 30;

export default {
  name: "TaskPeriodization",

  mixins: [model],

  data() {
    return {
      timeOptions,
      weekdayOptions,
      dayOptions,
      range: [defaultFromDay, defaultToDay],
    };
  },

  computed: {
    marks() {
      const firstDayInRange = this.range[0];
      const lastDayInRange = this.range[1];
      const defaultMarks = {
        1: "Día 1",
        365: "1 año",
      };

      return {
        [firstDayInRange]: `Día ${firstDayInRange}`,
        [lastDayInRange]: `Día ${lastDayInRange}`,
        ...defaultMarks,
      };
    },

    explicitDaysSummary() {
      const selectedDays = this.model.periodization.days
        .map((day) => `<b>${day}</b>`)
        .join(", ");
      return `Los días ${selectedDays}`;
    },

    rangeDaysSummary() {
      if (this.model.periodization.days.length === 7) {
        return "<b>Todos</b> los días";
      }

      const days = this.weekdayOptions
        .filter((d) => this.model.periodization.days.includes(d.value))
        .map((d) => `<b>${d.name}</b>`)
        .join(", ");

      if (this.model.periodization.days.length === 1) {
        return `Durante el día ${days}`;
      }

      const lastComma = days.lastIndexOf(", ");
      return `Durante los días ${days.substring(
        0,
        lastComma
      )} y ${days.substring(lastComma + 1)}`;
    },
  },

  created() {
    this.range = [
      this.model.periodization.fromDay || defaultFromDay,
      this.model.periodization.toDay || defaultToDay,
    ];
  },

  watch: {
    "periodization.type"() {
      this.model.periodization.days = [];
    },

    range() {
      this.model.periodization.fromDay = this.range[0];
      this.model.periodization.toDay = this.range[1];
    },
  },

  methods: {
    toggleDay(day) {
      const index = this.model.periodization.days.findIndex((d) => d === day);
      if (index >= 0) {
        this.model.periodization.days.splice(index, 1);
      } else {
        this.model.periodization.days.push(day);
      }
    },

    selectDay(day) {
      if (day !== 7) {
        this.toggleDay(day);
        return;
      }

      const hasAllSelected = this.model.periodization.days.includes(6);
      if (hasAllSelected) {
        this.model.periodization.days = [];
      } else {
        weekdayOptions.forEach((d) => {
          const isAllOption = d.value === 7;
          const isAlreadyIncluded = this.model.periodization.days.includes(
            d.value
          );
          if (!isAllOption && !isAlreadyIncluded) {
            this.model.periodization.days.push(d.value);
          }
        });
      }
    },

    isDayActive(day) {
      if (day === 7 && this.model.periodization.days.length === 7) {
        return true;
      }

      return this.model.periodization.days.includes(day);
    },
  },
};
</script>

<template lang="pug">
.modal__block
  .modal__section
    .modal__sign.sign
      .sign__icon
        micon(name="event_available")
      h3.sign__title Periodización
    article.modal__fields.periodization-module

      .row
        .types
          el-radio(v-model="model.periodization.type", label="range") Periódico
          el-radio(v-model="model.periodization.type", label="explicit") Días específicos

      .template(v-if="model.periodization.type === 'range'")
        .row
          fieldset.modal__field
            label.label * Cantidad de días
            el-slider(v-model="range" range :min="1" :max="365" :marks="marks" :show-tooltip="false")

        .row
          fieldset
            label.label * Días de la semana
            .weekdays-options.tags
                el-tag.weekday-option(
                  v-for="day in weekdayOptions"
                  :key="day.value"
                  :class="isDayActive(day.value) ? 'selected': '' "
                  @click="selectDay(day.value)",
                  @close="selectDay(day.value)",
                  effect="plain"
                  size="small"
                ) {{ day.name }}

      .template(v-else-if="model.periodization.type === 'explicit'")
        .row
          fieldset.modal__field
            label.label * Días en los que se dispara la tarea
            el-select.days-selector(v-model="model.periodization.days" placeholder="1, 5, 12, etc" filterable multiple default-first-option)
              el-option(
                v-for="option in dayOptions"
                :key="option"
                :label="option"
                :value="option"
              )

      .row
        fieldset.modal__field
          label.label * Horarios
          el-select.hours-selector(v-model="model.periodization.hours" placeholder="09:00, 18:30, etc" filterable multiple default-first-option)
            el-option(
              v-for="option in timeOptions"
              :key="option"
              :label="option"
              :value="option"
            )

      .row.periodization-summary
        fieldset
          span.title Esta tarea se programará:
          ul
            li(v-if="model.periodization.type === 'range'")
              span.error(v-if="!model.periodization.fromDay || !model.periodization.toDay") Debe seleccionar el día desde y hasta
              span(v-else)
                | Desde el día
                b {{' '}} {{ model.periodization.fromDay }} {{' '}}
                | hasta el día
                b {{' '}} {{ model.periodization.toDay }} {{' '}}
                | del protocolo
            li
              span.error(v-if="!model.periodization.days.length") Debe seleccionar los días
              span(v-if="model.periodization.days.length && model.periodization.type === 'range'" v-html="rangeDaysSummary")
              span(v-if="model.periodization.days.length && model.periodization.type === 'explicit'" v-html="explicitDaysSummary")
            li
              span.error(v-if="!model.periodization.hours.length") Debe seleccionar los horarios
              template(v-if="model.periodization.hours.length")
                span En los horarios&nbsp;
                span.hour(v-for="hour in model.periodization.hours" :key="hour")
                  span
                    b {{ hour }}
                    b
                      small hs.
</template>

<style lang="scss">
.periodization-module {
  max-width: 50%;

  .row {
    margin-bottom: 10px;
  }

  .el-slider {
    padding: 0 15px;

    .el-slider__marks {
      .el-slider__marks-text {
        margin-top: -25px;
      }

      .el-slider__marks-text:first-child {
        margin-top: 15px;
      }

      .el-slider__marks-text:last-child {
        margin-top: 15px;
      }

      .el-slider__marks-text {
        text-align: center;
        width: 50px;
      }
    }
  }

  .weekdays-options {
    display: flex;

    .weekday-option {
      cursor: pointer;
      border-color: $cuttysark;
      color: $cuttysark;
      padding: 0px 7px;
      transition: opacity 250ms;

      &:hover {
        opacity: 0.9;
      }

      &.selected {
        border-color: $primary;
        color: $primary;
        background: $primary;
        color: #ffffffff;
        &::after {
          content: "╳";
          font-size: 8px;
          margin-left: 6px;
          color: #ffffffff;
          font-weight: bold;
        }
      }
    }
  }

  .el-divider__text {
    color: $primary-alt;
  }

  .periodization-summary {
    margin-bottom: 0;

    fieldset {
      width: 100%;
      padding-bottom: 0;
    }

    .title {
      color: $primary-alt;
    }

    ul {
      display: flex;
      flex-direction: column;
      list-style-type: disc;
      padding-left: 20px;
      color: $cuttysark;

      .error {
        color: $flamingo;
      }

      .hour {
        margin-right: 5px;
      }
    }
  }
}
</style>
