import api from "./api";
import helperService from "./helper";

const taskConfigService = {};

// API calls

const endpoint = (type) => `/tasks/${type}/type/default`;

taskConfigService.create = function (task, type) {
  return api.post(endpoint(type), task);
};

taskConfigService.get = function (
  type,
  query,
  config = { filterMongoProps: false }
) {
  return api
    .get(endpoint(type), { params: query })
    .then((res) =>
      config.filterMongoProps
        ? helperService.filterMongoProps(res.data.docs)
        : res.data
    );
};

taskConfigService.getById = function ({ taskID, taskType }) {
  return api.get(`${endpoint(taskType)}/${taskID}`).then((res) => res.data);
};

taskConfigService.update = function (task, type) {
  return api.put(`${endpoint(type)}/${task._id}`, task);
};

taskConfigService.delete = function (id, type) {
  return api.delete(`${endpoint(type)}/${id}`);
};

taskConfigService.getCategories = function (type) {
  return api.get(`${endpoint(type)}/categories`);
};

export default taskConfigService;
