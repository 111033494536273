<script>
export default {
  name: "TaskDetailHeader",

  props: {
    isUpdate: {
      type: Boolean,
      required: true,
    },

    isLoading: {
      type: Boolean,
      required: true,
    },

    isSubmit: {
      type: Boolean,
      default: false,
    },

    displayName: {
      type: String,
      required: true,
    },

    close: {
      type: Function,
      required: true,
    },

    onSave: {
      type: Function,
      default: () => {},
      required: false,
    },
  },

  computed: {
    createOrUpdateLabel() {
      return this.isUpdate ? "Guardar" : "Crear";
    },

    nativeType() {
      return this.isSubmit ? "submit" : "button";
    },
  },
};
</script>

<template lang="pug">
header.modal__header
  h2.modal__title {{ createOrUpdateLabel }} {{ displayName }}
  .modal__actions
    el-button(type="info" @click="close") Cancelar
    el-button.border(
      type="primary"
      native-type="nativeType"
      :loading="isLoading"
      @click="onSave"
    ) Guardar
</template>
