<script>
import model from "@/mixins/model";
import defaultTaskService from "@/services/default-task";

export default {
  name: "TaskDefaults",

  mixins: [model],

  data() {
    return {
      selectedDefault: "",
      defaults: [],
    };
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  created() {
    this.getDefaults();
  },

  methods: {
    getDefaults() {
      return defaultTaskService
        .get(this.type, "", { filterMongoProps: true })
        .then((defaults) => {
          this.defaults = defaults;
        });
    },
  },

  watch: {
    selectedDefault: function (name) {
      if (!name) {
        return;
      }

      const data = this.defaults.find((d) => d.name === name);

      if (this.type === "reports") {
        data.recommendations =
          data &&
          data.recommendations.map((recommendation) => {
            delete recommendation._id;
            return recommendation;
          });
      }

      this.model = { ...this.model, ...data };
    },
  },
};
</script>

<template lang="pug">
  .modal__row
    fieldset.modal__field
      label.label Tipo de Configuración:
      el-select(v-model="selectedDefault" placeholder="" filterable default-first-option)
        el-option(
          v-for="def in defaults"
          :key="def.id"
          :label="def.name"
          :value="def.name"
        )
</template>
