var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal__block"},[_c('div',{staticClass:"modal__section"},[_c('div',{staticClass:"modal__sign sign"},[_c('div',{staticClass:"sign__icon"},[_c('micon',{attrs:{"name":"assignment_turned_in"}})],1),_c('h3',{staticClass:"sign__title"},[_vm._v("Ejecución")])]),_c('article',{staticClass:"modal__fields"},[_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Repeticiones:")]),_c('ValidationProvider',{attrs:{"name":"Repeticiones","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{class:{'has-error': errors[0]},attrs:{"type":"number","step":"any","min":"0"},model:{value:(_vm.model.repetitions),callback:function ($$v) {_vm.$set(_vm.model, "repetitions", $$v)},expression:"model.repetitions"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Peso (KG):")]),_c('ValidationProvider',{attrs:{"name":"Peso","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{class:{'has-error': errors[0]},attrs:{"type":"number","step":"any","min":"0"},model:{value:(_vm.model.weight),callback:function ($$v) {_vm.$set(_vm.model, "weight", $$v)},expression:"model.weight"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Series:")]),_c('ValidationProvider',{attrs:{"name":"Series","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{class:{'has-error': errors[0]},attrs:{"type":"number","step":"any","min":"0"},model:{value:(_vm.model.series),callback:function ($$v) {_vm.$set(_vm.model, "series", $$v)},expression:"model.series"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"modal__row"},[_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Duración (Min):")]),_c('ValidationProvider',{attrs:{"name":"Duración","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(_vm.model.duration),callback:function ($$v) {_vm.$set(_vm.model, "duration", $$v)},expression:"model.duration"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('fieldset',{staticClass:"detail__field"},[_c('label',{staticClass:"label"},[_vm._v("* Distancia (Mts):")]),_c('ValidationProvider',{attrs:{"name":"Distancia","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{class:{'has-error': errors[0]},attrs:{"type":"number","step":"any","min":"0"},model:{value:(_vm.model.distance),callback:function ($$v) {_vm.$set(_vm.model, "distance", $$v)},expression:"model.distance"}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }