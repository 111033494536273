<script>
// This is a generic component that shows a form to create or update a task.
// The computed property `formType` is going to return one of these 3 types of tasks:
// - "config-task"
// - "treatment-task"
// - "patient-task"

import cloneDeep from "clone-deep";

import leave from "@/mixins/leave";

import fileUploadConfigService from "@/services/fileupload-config";
import defaultTaskService from "@/services/default-task";
import treatmentTaskService from "@/services/treatment-task";
import patientTaskService from "@/services/patient-task";
import dateHelpersService from "@/services/date-helpers";
import { TASKS_TYPES, getDefaultTaskData } from "@/services/constants";
import segmentService from "@/services/segment";

import Spinner from "@/components/ui/Spinner";
import FileUpload from "@/components/ui/FileUpload";
import TaskPeriodization from "@/components/task/TaskPeriodization";
import TaskNotification from "@/components/task/TaskNotification";
import TaskDefaults from "@/components/task/TaskDefaults";
import TaskDetailHeader from "@/components/task/TaskDetailHeader";
import TaskGeneralForm from "@/components/task/TaskGeneralForm";
import TaskMeasurement from "@/components/task/TaskMeasurement";
import TaskLinks from "@/components/task/TaskLinks";
import TaskCheckinsForm from "@/components/task/TaskCheckinsForm";
import TaskAppointmentsForm from "@/components/task/TaskAppointmentsForm";
import TaskReportsForm from "@/components/task/TaskReportsForm";
import TaskExercisesForm from "@/components/task/TaskExercisesForm";
import TaskRecommendationsForm from "@/components/task/TaskRecommendationsForm";
import TaskMedicationsForm from "@/components/task/TaskMedicationsForm";
import TaskMedia from "@/components/task/TaskMedia";

export default {
  name: "TaskDetail",

  components: {
    FileUpload,
    Spinner,
    TaskPeriodization,
    TaskNotification,
    TaskDefaults,
    TaskDetailHeader,
    TaskGeneralForm,
    TaskMeasurement,
    TaskLinks,
    TaskCheckinsForm,
    TaskAppointmentsForm,
    TaskReportsForm,
    TaskExercisesForm,
    TaskRecommendationsForm,
    TaskMedicationsForm,
    TaskMedia,
  },

  mixins: [leave],

  data() {
    return {
      TASKS_TYPES,
      treatmentID: this.$route.params.id, // TODO: refactor this to be params.treatmentID
      patientID: this.$route.params.id, // TODO: refactor this to be params.patientID
      taskID: this.$route.params.taskID,
      taskType: this.$route.params.taskType,
      task: getDefaultTaskData(this.$route.params.taskType),
      displayName: TASKS_TYPES[this.$route.params.taskType].name,
      icon: TASKS_TYPES[this.$route.params.taskType].icon,
      isUpdate: this.$route.name.startsWith("update"),
      isFetching: false,
      isPosting: false,
      files: [],
    };
  },

  created() {
    document.addEventListener("keyup", this.onKeyUp);
    if (this.isUpdate) {
      this.getTask();
    }
  },

  beforeDestroy() {
    this.task = getDefaultTaskData(this.taskType);
    this.isFetching = false;
    this.isPosting = false;
    document.removeEventListener("keyup", this.onKeyUp);
  },

  computed: {
    formType() {
      switch (this.$route.name) {
        case "create-task":
        case "update-task":
          return "config-task";

        case "create-treatment-task":
        case "update-treatment-task":
          return "treatment-task";

        case "create-patient-task":
        case "update-patient-task":
          return "patient-task";

        default:
          return "";
      }
    },

    showConfigs() {
      return (
        this.formType !== "config-task" && TASKS_TYPES[this.taskType].hasConfig
      );
    },

    showMedia() {
      return TASKS_TYPES[this.taskType].hasMedia;
    },

    showPeriodization() {
      return this.formType === "treatment-task" && this.taskType !== "reports";
    },

    uploadMediaEndpoint() {
      if (this.formType === "config-task") {
        return fileUploadConfigService.getTaskMediaEndpoint(
          this.taskType,
          "default",
          this.taskID
        );
      }

      if (this.formType === "treatment-task") {
        return fileUploadConfigService.getTaskMediaEndpoint(
          this.taskType,
          "treatment",
          this.taskID
        );
      }

      return fileUploadConfigService.getTaskMediaEndpoint(
        this.taskType,
        "patient",
        this.taskID
      );
    },
  },

  methods: {
    onKeyUp(event) {
      const esc = 27;
      if (event.keyCode == esc) {
        this.closeModal();
      }
    },

    closeModal() {
      this.$router.back();
    },

    getTask() {
      this.isFetching = true;
      let serviceCall;

      const options = {
        taskID: this.taskID,
        taskType: this.taskType,
      };

      if (this.formType === "config-task") {
        serviceCall = defaultTaskService.getById(options);
      } else if (this.formType === "treatment-task") {
        serviceCall = treatmentTaskService.getById(options);
      } else {
        serviceCall = patientTaskService.getById(options);
      }

      return serviceCall
        .then((task) => {
          if (this.showPeriodization && task.periodization.hours.length) {
            task.periodization.hours = this.getPeriozationHoursInLocal(task);
          }
          this.task = { ...this.task, ...task };
        })
        .finally(() => {
          this.isFetching = false;
        });
    },

    isValidPeriodization() {
      if (this.showPeriodization) {
        return (
          this.task.periodization.days.length &&
          this.task.periodization.hours.length
        );
      } else {
        return true;
      }
    },

    submit() {
      if (this.isValidPeriodization()) {
        this.upsertTask();
      }
    },

    getUpsertTaskServiceMethod(task) {
      const action = this.isUpdate ? "update" : "create";

      if (this.formType === "config-task") {
        return defaultTaskService[action](task, this.taskType);
      }

      if (this.formType === "treatment-task") {
        return treatmentTaskService[action]({
          taskID: this.taskID,
          taskType: this.taskType,
          treatmentID: this.treatmentID,
          task,
        });
      }

      if (this.formType === "patient-task") {
        return patientTaskService[action]({
          taskID: this.taskID,
          taskType: this.taskType,
          patientID: this.patientID,
          task,
        });
      }
    },

    upsertTask() {
      const eventName = this.isUpdate ? "Task Updated" : "Task Created";
      segmentService.track({ name: eventName });

      const isReport = this.taskType === "reports";
      this.isPosting = true;
      this.showLeaveGuard = false;

      const task = cloneDeep(this.task);

      if (this.showPeriodization) {
        task.periodization.hours = this.getPeriozationHoursInUTC(task);

        if (task.periodization.type === "explicit") {
          delete task.periodization.fromDay;
          delete task.periodization.toDay;
        }
      }

      task.links = (task.links || []).filter(
        (link) => link && link.name && link.url
      );

      if (isReport) {
        delete task.recommendations;
      }

      const serviceCall = this.getUpsertTaskServiceMethod(task);

      return serviceCall
        .then(({ data }) => {
          this.taskID = this.taskID || data._id;
          const createdTask = data || task;
          this.$bus.$emit("task-update", {
            ...createdTask,
            type: this.taskType,
          });

          if (isReport) {
            return this.$refs.taskReportsForm.saveRecommendations(this.taskID);
          }

          if (this.$refs.taskMedia) {
            this.$refs.taskMedia.uploadFiles();
          }
        })
        .finally(() => {
          // taskMedia component is going to call closeModal when finished
          if (!this.$refs.taskMedia) {
            this.isPosting = false;
            this.closeModal();
          }
        });
    },

    getPeriozationHoursInUTC(task) {
      return task.periodization.hours.map((time) =>
        dateHelpersService.localTimeToUTC(time)
      );
    },

    getPeriozationHoursInLocal(task) {
      return task.periodization.hours.map((time) =>
        dateHelpersService.UTCTimeToLocal(time)
      );
    },

    onMediaError() {
      this.isPosting = false;
    },
  },
};
</script>

<template lang="pug">
  ValidationObserver(v-slot="{handleSubmit}")
    form.modal(@submit.prevent="handleSubmit(submit)")
      task-detail-header(
        :is-update="isUpdate"
        :is-submit="true"
        :is-loading="isPosting"
        :display-name="displayName"
        :close="closeModal"
      )
      p.modal__subtitle Los campos con (*) son obligatorios

      .modal__content(v-if="isFetching")
        spinner

      .modal__content(v-else)
        .modal__block
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(:name="icon")
              h3.sign__title Información General

            article.modal__fields

              task-defaults(v-if="showConfigs" v-model="task" :type="taskType")

              task-general-form(v-model="task" :type="taskType")

              task-appointments-form(v-if="taskType === 'appointments'" v-model="task")

              task-recommendations-form(
                v-if="taskType === 'recommendations'"
                v-model="task"
                :is-update="isUpdate"
              )

              task-medications-form(v-if="taskType === 'medications'" v-model="task")

            article.modal__fields

        task-exercises-form(v-if="taskType === 'exercises'" v-model="task")

        task-checkins-form(v-if="taskType === 'checkins'" v-model="task")

        task-measurement(v-model="task" :type="taskType")

        task-media(
          v-if="showMedia"
          ref="taskMedia"
          v-model="task"
          :upload-media-endpoint="uploadMediaEndpoint"
          :close-modal="closeModal"
          :on-media-error="onMediaError"
          :type="taskType"
        )

        task-links(v-if="taskType !== 'reports'" v-model="task")

        task-reports-form(
          v-if="taskType === 'reports'"
          ref="taskReportsForm"
          v-model="task"
          :is-update="isUpdate"
          :form-type="formType"
          :close-modal="closeModal"
        )

        task-periodization(v-if="showPeriodization" v-model="task")
        task-notification(v-model="task" :showPeriodization="showPeriodization")
</template>
