<script>
import model from "@/mixins/model";
import InputTag from "@/components/ui/InputTag";
import defaultTaskService from "@/services/default-task";

export default {
  name: "TaskGeneralForm",

  components: {
    InputTag,
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      categories: [],
    };
  },

  mixins: [model],

  created() {
    this.getCategories();
  },

  methods: {
    async getCategories() {
      if (this.type === "reports") {
        const { data } = await defaultTaskService.getCategories(this.type);
        this.categories = data;
      }
    },
  },
};
</script>

<template lang="pug">
  ValidationObserver
    span
      .modal__row
        fieldset.modal__field
          label.label * Nombre:
          ValidationProvider(name="Nombre", rules="required", v-slot="{ errors }")
            el-input(v-model="model.name")
            span.has-error {{ errors[0] }}

      .modal__row(v-if="type==='reports'")
        fieldset.modal__field
          label.label Categoría:
          el-select(
            v-model="model.category"
            allow-create
            filterable
            )
            el-option(
              v-for="category in categories"
              :key='category'
              :value='category'
              :label= 'category.toUpperCase()'
              )

      .modal__row
        fieldset.modal__field
          label.label Descripción:
          el-input(
            type="textarea"
            v-model="model.description"
            name="Descripción"
            rows="10"
            maxlength="250"
          )

      .modal__row
        fieldset.modal__field
          label.label Etiquetas:
          input-tag(v-model="model.tags")
</template>
