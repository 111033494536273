import api from "./api";
import axios from "axios";
import authService from "@/services/auth";
import configService from "./config";

const reportRecommendationsService = {};

// modelType: default | treatment | patient
const endpoint = ({ modelType, taskID, recommendationID = "" }) =>
  `tasks/reports/type/${modelType}/${taskID}/recommendations/${recommendationID}`;

reportRecommendationsService.create = function ({
  modelType,
  taskID,
  recommendation,
}) {
  const needsToUploadFile =
    recommendation.file && !!recommendation.file.dataURL;

  let data;

  if (needsToUploadFile) {
    data = new FormData();
    data.append("title", recommendation.title);
    data.append("description", recommendation.description);
    data.append("file", recommendation.file, recommendation.file.name);
    data.append("videoUrl", recommendation.videoUrl);
  } else {
    data = recommendation;
  }

  const token = `Bearer ${authService.getToken()}`;
  const contentType = needsToUploadFile
    ? "multipart/form-data"
    : "application/json";

  return axios
    .post(`${configService.apiUrl}/${endpoint({ modelType, taskID })}`, data, {
      headers: {
        Authorization: token,
        "Content-Type": contentType,
      },
    })
    .then((res) => res.data);
};

reportRecommendationsService.delete = function ({
  modelType,
  taskID,
  recommendationID,
}) {
  return api.delete(endpoint({ modelType, taskID, recommendationID }));
};

export default reportRecommendationsService;
