import api from "./api";

const treatmentTaskService = {};

const endpoint = (taskType) => `/tasks/${taskType}/type/treatment`;

treatmentTaskService.create = function ({ treatmentID, taskType, task }) {
  return api.post(endpoint(taskType), {
    ...task,
    treatment: treatmentID,
  });
};

treatmentTaskService.get = function ({ treatmentID, taskType }) {
  return api
    .get(endpoint(taskType), {
      params: { treatment: treatmentID },
    })
    .then((res) => res.data.docs);
};

treatmentTaskService.getById = function ({ taskType, taskID }) {
  return api.get(`${endpoint(taskType)}/${taskID}`).then((res) => res.data);
};

treatmentTaskService.update = function ({ taskType, task, taskID }) {
  return api
    .put(`${endpoint(taskType)}/${taskID}`, task)
    .then((res) => res.data);
};

treatmentTaskService.delete = function ({ taskType, taskID }) {
  return api.delete(`${endpoint(taskType)}/${taskID}`).then((res) => res.data);
};

export default treatmentTaskService;
